import React from "react";
import { graphql } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import { BgImage } from "gbimage-bridge";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import Testimonials from "../components/Repeating/Testimonials";
import About from "../components/Repeating/About";
import CallToAction from "../components/Repeating/CTA";
import PersonalServiceSidebar from "../components/Repeating/PersonalServiceSidebar";
import PersonalServiceSlider from "../components/Repeating/PersonalServiceSlider";
import ButtonSolid from "../components/Button/ButtonSolid";

const Page = ({ data }) => {
  return (
    <Layout>
      <SearchEngineOptimization
        title="Estate & Trust Planning Services | DC, MD & VA | DeBlanc"
        description="Don't wait to create an estate plan. At DeBlanc + Murphy, we offer comprehensive estate and trust planning services. Serving the greater Washington, DC, area."
        // openGraphImage={data.openGraphImage.publicURL}
        // twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
      />
      <section className="pt-20 pb-10 md:py-14">
        <div className="container">
          <div className="max-w-[800px]">
            <h1>Estate, Gift & Trust Tax Planning</h1>
            <p className="mb-0 md:text-xl">
              We help you plan for the transfer of your assets to beneficiaries,
              such as a surviving spouse or your children. Serving the greater
              Washington, DC area.
            </p>
          </div>
        </div>
      </section>

      <section className="relative mb-4 h-[375px] md:mb-32 md:h-[504px]">
        <BgImage
          image={getImage(data.parallaxImage.childImageSharp.gatsbyImageData)}
          className="h-full w-full bg-fixed"
        ></BgImage>
        <div className="gradient-blend absolute bottom-0 h-28 w-full"></div>
      </section>

      <section className="relative mb-20 md:mb-32">
        <div className="container">
          <div className="grid gap-y-20 md:grid-cols-12">
            <div className="md:col-span-8 md:col-start-1">
              <div className="mb-16">
                <h2>Estate & Gift Tax Planning</h2>
                <p>
                When it comes to the Estate and Gift Tax, failing to plan is planning to fail. If you hope to successfully transfer wealth to future generations, estate and gift planning is critical, and it begins with a comprehensive tax and personal financial plan. Let us help you create a plan to minimize taxes and create a legacy that lasts for generations.
                </p>
                <p>
                We can help you:
                </p>
                <ul className="styled-list-plus alt mb-6">
                  <li>
                  Develop a plan that ensures your wishes are honored, your taxes are minimized, and your legacy is protected</li>
                  <li>Develop and execute charitable gifting strategies that can be implemented during your lifetime so you can see the impact of your philanthropic efforts</li>
                  <li>Develop a plan for the succession of closely held business from one generation to the next</li>
                  <li>Review estate planning documents and make recommendations</li>
                  <li>Avoid the cost and publicity associated with probate</li>

                </ul>
              </div>

              <div className="mb-16">
                <h2>Estate & Trust Administration</h2>
                <p>Administering estates and trusts is complex and it requires detailed recordkeeping and reporting. Asset inventories and court accountings need to be prepared. Valuations need to be made. Expenses need to be paid. Tax returns need to be filed.  </p>
                <p>Our team of experts can help you fulfill your fiduciary duty to the estate or trust and keep you in compliance with federal, state, and local authorities.</p>
              </div>

              <div>
                <h2>Estate, Gift & Trust Tax Return Preparation</h2>
                <p>
                As expert estate and trust accountants, we can prepare all required tax filings, including:
                </p>

                <ul className="styled-list-plus alt mb-6">
                  <li>
                  Final Individual Income Tax Return for the year of death, Form 1040</li>
              <li>Decedent’s Estate Income Tax Return, Form 1041</li>
              <li>Estate Tax Return, Form 706</li>
              <li>Gift Tax Return, Form 709</li>
              <li>Trust Tax Return, Form 1041</li>
              <li>Split-Interest Trust Tax Return, Form 5227

                  </li>
                </ul>

                <ButtonSolid modal="modal-contact" altStyle={2} text="Get a Quote" />
              </div>
            </div>
            <div className="hidden md:col-span-4 md:col-end-13 md:block">
              <PersonalServiceSidebar activeService={1} />
            </div>
          </div>
        </div>
      </section>

      <Testimonials />
      <PersonalServiceSlider hideService={1} />
      <About paddingTop={true} />
      <CallToAction />
    </Layout>
  );
};

export const data = graphql`
  {
    parallaxImage: file(
      relativePath: { eq: "3.2 Estate _ Trust Planningal/1.0 Hero.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
  }
`;

export default Page;
